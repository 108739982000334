import { useState } from 'react';
import { Buffer } from 'buffer';
import { app, authentication } from '@microsoft/teams-js';
import { allowedDomains } from './settings/settings';
import logo from './logo.svg';
import './App.css';

function App() {
  let [error, setError] = useState(0);
  let [username, setUsername] = useState('');
  let [classname, setClassname] = useState('App-logo');
  let token = '';
  app.initialize()
    .then(() => {
      authentication.getAuthToken()
        .then((authToken) => {
          token = authToken;
          let segments = token.split('.');
          if (segments.length > 1) {
            let info = JSON.parse(Buffer.from(segments[1], 'base64').toString());
            setUsername(info.unique_name ?? info.preferred_username ?? '');
          }
          if (username !== '') {
            if (allow(username)) {
              redirect(username, token);
              setError(0);
            }
            else {
              setError(-1);
              setClassname('');
            }
          }
          else {
            app.getContext()
              .then((context) => {
                setUsername(context.user?.userPrincipalName ?? '');
                if (allow(username)) {
                  redirect(username, token);
                  setError(0);
                }
                else {
                  setError(-1);
                  setClassname('');
                }
              })
              .catch(() => {
                setError(-1);
                setClassname('');
              });
          }
        })
        .catch(() => {
          setError(2);
          setClassname('');
        })
    })
    .catch(() => {
      setError(1);
      setClassname('');
    })
  return (
    <div className="App">
      <div >
        <div className='Main'>
          <img src={logo} alt="" width={48} height={48} className={classname}></img>
          {getDescription(error, username)}
        </div>
      </div>
    </div>
  );
}
export default App;

function getDescription(error: number, username: string): JSX.Element {
  switch (error) {
    case 0:
      return (<p>Welcome to Teams CX Report</p>);
    case 1:
      return (<p>Failed to initialize Teams client</p>);
    case 2:
      return (<p>Failed to get Teams token</p>);
    case 3:
      return (<p>Invalid Teams token</p>);
    default: {
      if (username === '')
        return (<p>You don’t have permission to access reports.<br />Please contact your administrator for further information.</p>);
      else
        return (<p>User {username} lacks of privilege to access reports.<br />Please contact your administrator for further information.</p>);
    }
  }
}

function allow(username: string): boolean {
  let allowed = false;
  username = username.toLowerCase();
  allowedDomains.forEach(e => {
    if (username.includes(e.toLowerCase()))
      allowed = true;
  });
  return allowed;
}

function redirect(username: string, token: string) {
  var url = window.location.search;
  let newURL = url.replace("?url=", " ");
  // Decode the String
  let decodedUrl = Buffer.from(newURL, 'base64').toString('ascii');
  window.location.href = `${decodedUrl}&auth_token=${token}&var-user=${username}`;
}